<template>
  <div>
    <b-card class="mt-0" header="Feedback">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">

        <b-container fluid>
          <b-row>
            <b-col>
              <b-form-group id="input-last-name" label="First Name:" label-for="first-name">
              <b-form-input
                id="first-name"
                v-model="form.firstName"
                placeholder="Enter first name"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="input-last-name" label="Last Name:" label-for="last-name">
              <b-form-input
                id="last-name"
                v-model="form.lastName"
                placeholder="Enter last name"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group id="email" label="Email:" label-for="input-email">
              <b-form-input
                id="input-email"
                v-model="form.email"
                placeholder="Enter email address"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group id="entity" label="Suggested entities:" label-for="input-entity">
               <b-form-textarea
                id="input-entity"
                v-model="form.entity"
                placeholder="Enter suggested entities"
                rows="3"
                max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        <b-row>
          <b-col>
            <b-form-group id="link" label="Suggested links:" label-for="input-link">
               <b-form-textarea
                id="input-links"
                v-model="form.link"
                placeholder="Enter suggested links"
                rows="3"
                max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        <b-row>
          <b-col>
            <b-form-group id="interaction" label="Suggested intractions:" label-for="input-interaction">
               <b-form-textarea
                id="input-interaction"
                v-model="form.interaction"
                placeholder="Enter suggested interactions"
                rows="3"
                max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        <b-row>
          <b-col>
            <b-form-group id="comment" label="Suggested comments:" label-for="input-comment">
               <b-form-textarea
                id="input-comment"
                v-model="form.comment"
                placeholder="Enter suggested comments"
                rows="3"
                max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>

        </b-container>

        <b-button type="submit" variant="primary">Submit</b-button>
        <b-button type="reset" variant="danger" class="ml-2">Reset</b-button>
      </b-form>
    </b-card>

<!--
    <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
-->
  </div>
</template>

<script>
  import $ from 'jquery'
  import EventBus from './eventbus'

  export default {
    name: 'FeedbackPanel',
    components: {
    },
    data () {
      return {
        form: {
          email: '',
          firstName: '',
          lastName: '',
          entity: '',
          link: '',
          interaction: '',
          comment: '',
          //food: null,
          //checked: []
        },
        foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
        show: true
      }
    },
    computed: {
    },
    mounted() {
      //var self = this
      //console.log("send event")
      EventBus.$emit('showNodeProperty', false)
    },
    methods: {
      formatter(value) {
        return value.toLowerCase()
      },
      async onSubmit(event) {
        event.preventDefault()
        console.log(JSON.stringify(this.form))

        const ctrl = new AbortController()    // timeout
        setTimeout(() => ctrl.abort(), 5000);

        try {
          let r = await fetch('https://bmistage.biostat.wisc.edu/crew/api/v1/feedback',
            {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              method: "POST",
              body: JSON.stringify(this.form),
              signal: ctrl.signal});
          console.log('HTTP response code: ', r.status);
        }
        catch(e) {
          console.log('HTTP failed to send: ', e);
        }
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.firstName = ''
        this.form.lastName = ''
        this.form.entity = ''
        this.form.link = ''
        this.form.interaction = ''
        this.form.comment = ''
        //this.form.food = null
        //this.form.checked = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }

    }
  }
</script>
<style scoped>
  .noscroll {
    overflow: hidden;
  }

  .graph-view {
    width: 100%;
  }
</style>
