<template>
  <div id="feedback" class="wrapper">
    <grid-layout :layout.sync="layout"
      :col-num="12"
      :row-height="30"
      :is-draggable="true"
      :is-resizable="true"
      :vertical-compact="true"
      :use-css-transforms="true">
      <grid-item v-for="item in layout"
        :static="item.static"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        drag-allow-from=".vue-draggable-handle"
        drag-ignore-from=".no-drag">
        <div class="text">
          <div v-if="!item.static" class="vue-draggable-handle">
          </div>
          <div class="no-drag">
            <template>
              <component v-if="item.isComponent" :is="item.c"></component>
              <div v-else v-html="item.c"></div>
            </template>
          </div>
        </div>
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
  import VueGridLayout from 'vue-grid-layout';
  import FeedbackPanel from '../components/FeedbackPanel.vue'
  import Navbar from '../components/Navbar.vue'

  export default {
    name: 'Feedback',
    components: {
      GridLayout: VueGridLayout.GridLayout,
      GridItem: VueGridLayout.GridItem,
      FeedbackPanel,
      Navbar,
    },
    data() {
      return {
        layout: [
          {"x":0,"y":1,"w":12,"h":29,"i":"0", static: true, "c": 'FeedbackPanel', isComponent: true},
          {"x":0,"y":0,"w":12,"h":1,"i":"1", static: true, "c": 'Navbar', isComponent: true},
            ],
            draggable: true,
            resizable: true,
            index: 0,
            eventLog: []
        }
    },
    watch: {
        eventLog: function() {
            const eventsDiv = this.$refs.eventsDiv;
            eventsDiv.scrollTop = eventsDiv.scrollHeight;
        }
    },
    methods: {
        itemTitle(item) {
            let result = item.i;
            if (item.static) {
                result += " - Static";
            }
            return result;
        },
        moveEvent: function(i, newX, newY){
            const msg = "MOVE i=" + i + ", X=" + newX + ", Y=" + newY;
            this.eventLog.push(msg);
            console.log(msg);
        },
        movedEvent: function(i, newX, newY){
            const msg = "MOVED i=" + i + ", X=" + newX + ", Y=" + newY;
            this.eventLog.push(msg);
            console.log(msg);
        },
        resizeEvent: function(i, newH, newW, newHPx, newWPx){
            const msg = "RESIZE i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
            this.eventLog.push(msg);
            console.log(msg);
        },
        resizedEvent: function(i, newX, newY, newHPx, newWPx){
            const msg = "RESIZED i=" + i + ", X=" + newX + ", Y=" + newY + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
            this.eventLog.push(msg);
            console.log(msg);
        },
        containerResizedEvent: function(i, newH, newW, newHPx, newWPx){
            const msg = "CONTAINER RESIZED i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx;
            this.eventLog.push(msg);
            console.log(msg);
        },
        layoutCreatedEvent: function(newLayout){
            this.eventLog.push("Created layout");
            console.log("Created layout: ", newLayout)
        },
        layoutBeforeMountEvent: function(newLayout){
            this.eventLog.push("beforeMount layout");
            console.log("beforeMount layout: ", newLayout)
        },
        layoutMountedEvent: function(newLayout){
            this.eventLog.push("Mounted layout");
            console.log("Mounted layout: ", newLayout)
        },
        layoutReadyEvent: function(newLayout){
            this.eventLog.push("Ready layout");
            console.log("Ready layout: ", newLayout)
        },
        layoutUpdatedEvent: function(newLayout){
            this.eventLog.push("Updated layout");
            console.log("Updated layout: ", newLayout)
        },
    }
  }
</script>

<style scoped>
.vue-grid-layout {
  background: #eee;
}
#feedback >>> .vue-resizable-handle {
  //background-color: coral;
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: 0;
  right: 0;
  //background: none;
  background-position: 100% 100%;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: se-resize;
}
.vue-grid-item:not(.vue-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}

.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .text {
  font-size: 24px;
  //text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-draggable-handle {
  position: absolute;
  //width: 20px;
  width: 100%;
  height: 20px;
  top: 0;
  left: 0;
  //background-color: coral;
  //padding: 0 8px 8px 0;
  box-sizing: border-box;
  cursor: move;
  z-index: 9999;
}
</style>
